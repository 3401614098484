

















import { Vue, Component, Prop } from 'vue-property-decorator';
import Swiper from 'swiper';
import { EventBus } from '@/config/config';

@Component
export default class ThumbnailListModal extends Vue {
  @Prop()
  public title!: any;

  @Prop()
  public titleType!: any;

  @Prop({
    required: true
  })
  public thumbnail!: any;

  public thumbnailIndex: any = 0;

  public thumbnailDetailModal(index) {
    this.thumbnailIndex = index;
    EventBus.$emit('thumbnailIndex', this.thumbnailIndex);
    this.$modal.show('thumbnailDetail');
    this.$modal.hide('thumbnailList');
  }

  public modalOpened() {
    document.querySelector('html' as any).classList.add('open-modal');
  }
  public modalClosed() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }
  public destroyed() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }
}
